import React from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import pubsub from "@viewlift/pubsub";
import ModuleLoader from "../ModuleLoader";
import "./index.scss";
var NotFound404 = function () {
    var navigate = useNavigate();
    var goToHome = function () {
        var redirectPath = getLocalizedVal("redirectPath") || "/";
        pubsub.publish('apploading', true);
        navigate(redirectPath);
    };
    function getLocalizedVal(path) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (typeof window !== "undefined") {
            var messages = (_b = (_a = window === null || window === void 0 ? void 0 : window.app_data) === null || _a === void 0 ? void 0 : _a.appcmsMain) === null || _b === void 0 ? void 0 : _b.genericMessages;
            var chosenLangCode = Cookies.get('userLanguageSelection');
            if (chosenLangCode === "" && ((_d = (_c = window === null || window === void 0 ? void 0 : window.app_data) === null || _c === void 0 ? void 0 : _c.appcmsMain) === null || _d === void 0 ? void 0 : _d.languages)) {
                chosenLangCode = ((_h = (_g = (_f = (_e = window === null || window === void 0 ? void 0 : window.app_data) === null || _e === void 0 ? void 0 : _e.appcmsMain) === null || _f === void 0 ? void 0 : _f.languages) === null || _g === void 0 ? void 0 : _g.default) === null || _h === void 0 ? void 0 : _h.code) || "en";
            }
            var localizedVal = null;
            var defaultVal = messages[path];
            return localizedVal || defaultVal;
        }
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(ModuleLoader, null),
        React.createElement("div", { className: 'notFoundWrapper' },
            React.createElement("div", { className: 'bottomWrapper' },
                React.createElement("div", { className: 'title' }, getLocalizedVal("pageNotFoundTitle") || "Page Not Found"),
                React.createElement("div", { className: 'subTitle' }, getLocalizedVal("pageNotFoundSubtitle") || "Sorry, we can't find the page you were looking for. Error: 404."),
                React.createElement("div", { className: 'submit', onClick: goToHome }, getLocalizedVal("redirectButtonCta") || "Return to Homepage")))));
};
export default NotFound404;
