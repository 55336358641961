let config = {
    buildNumber: "1787",
    cachedAPI: "https://api-cache.uat.monumentalsportsnetwork.com",
    apiBaseUrl:"https://api.uat.monumentalsportsnetwork.com",
    apiKey:  "GcJBaiAK8Q4brxo5dMJS64DhsP0nPtXi5oy5RrIE",
    analyticsBaseUrl:"https://analytics-api.uat.monumentalsportsnetwork.com/user-beacon",
    sseBaseUrl: "https://event.uat.monumentalsportsnetwork.com",
    wssBaseUrl: "wss://ws.uat.monumentalsportsnetwork.com",
    appCMSBaseUrl: "https://appcms.uat.monumentalsportsnetwork.com",
    buildAssetsUrl: "https://appcms.uat.monumentalsportsnetwork.com",
    toolsUrl: "https://cms.uat.monumentalsportsnetwork.com",
    templateBuilderUrl: "https://template-builder.uat.monumentalsportsnetwork.com",
    paymentWSUrl: "wss://ws.uat.monumentalsportsnetwork.com",
    internalEmbedBaseURL: "https://api-internal.uat.monumentalsportsnetwork.com",
    filesProxy: {
        directPaths: [
          '/apple-app-site-association',
          '/.well-known/apple-app-site-association',
          '/.well-known/assetlinks.json',
          '/robots.txt',
          '/ads.txt',
          '/app-ads.txt',
          '/sitemap1.xml',
          '/sitemap2.xml',
          '/sitemap3.xml',
          '/sitemap4.xml',
          '/sitemap5.xml',
          '/sitemap6.xml',
          '/sitemap7.xml',
          '/sitemap8.xml',
          '/sitemap9.xml',
          '/sitemap10.xml',
          '/offline.html',
          '/serviceWorker.js',
          '/sw.js'
        ]
      },
    env:"localhost"
   }
   module.exports.config = config;
