import React, { lazy, Suspense, useState, useEffect, useRef,useCallback } from "react";
import FetchHook from "./useFetchHook";
import config from './moduleMap'
import loadable from '@loadable/component'
import Spinner from "../client/components/spinner";
import {ErrorBoundary} from 'react-error-boundary'

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

export const Modules = (props) => {
    const siteConfig = props && props.siteConfig
    const [loading, setLoading] = useState(false);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [newModules, setNewModules] = useState([]);
    const pagedata = props.pagedata
    const offset = props.offset;
  

    const loadData = function () {
        setLoading(true)
        loadNextComponents();
      };
    
      const isBottomOfPage = () => {
        if( typeof window !== "undefined"){ 
          const windowHeight = window.innerHeight;
          const body = window.document.body;
          const html = window.document.documentElement;
          const docHeight = Math.max(
            body.scrollHeight,
            body.offsetHeight,
            html.clientHeight,
            html.scrollHeight,
            html.offsetHeight
          );
          const windowBottom = windowHeight + window.pageYOffset + 300;
          return windowBottom >= (docHeight);
        }
        else return
      };
    
      const handleScroll =(event) => {
        event && event.preventDefault();
        if (isBottomOfPage() && offset < props.moduleCount && !loading) {
          loadData();
        }
      }

      const callHandleScroll = debounce(handleScroll, 50);

      const checkScroll = function (e) {
        callHandleScroll();
      };

      function debounce(func, timeout){
        let timer;
        return (...args) => {
          if (!timer) {
            func.apply(this, args);
          }
          clearTimeout(timer);
          timer = setTimeout(() => {
            timer = undefined;
          }, timeout);
        };
      }
  

      useEffect(() => {
        if(offset >= props.moduleCount){
          return
        }
    
          ['wheel', 'touchmove', 'scroll'].forEach((e) => {
              if( typeof window !== "undefined"){
                  window.addEventListener(e, checkScroll, {
                    passive: false,
                });
              }
            });
    
    
        return () => {
          ['wheel', 'touchmove', 'scroll'].forEach((e) => {
            if( typeof window !== "undefined" || offset >= props.moduleCount){
              window.removeEventListener(e, checkScroll);
            }
          });
        };
      }, [pagedata]);
    
      const loadNextComponents =  () => {
        let limit = props.moduleCount - props.offset >= 4 ? 4 : props.moduleCount - props.offset
        FetchHook({offset:props.offset,limit:limit})
        .then((res) => {
            //   window.page_data = [...window?.page_data,...res?.data?.data?.page?.modules]

              const newModuleData = res?.data?.data?.page?.modules;
              if (newModuleData.length) {
                setNewModules(newModuleData);
                setLoading(false);
                setHasLoaded(true);
              } else {
                setHasLoaded(false);
                setLoading(false);
              }
              ['wheel', 'touchmove', 'scroll'].forEach((e) => {
                if( typeof window !== "undefined"){
                  window.removeEventListener(e, checkScroll);
                }  
              });
            
            
          })
          .catch((e)=> {
            // console.log("error",e)
          })
      };
    return(<>
            {hasLoaded && newModules.length ? newModules.map((module, index) => {
                let moduleMap = module?.layout?.view ? config.map[module.layout.view] : null
                if (!moduleMap) {
                    return console.log(`Module not found for '${moduleMap}'`)
                }
                let Component = loadable(() => import(`./modules/${moduleMap}`), { ssr: true});
            return(
                <div key={module.id}>
                  <ErrorBoundary fallback={<p>Something went Wrong</p>}>
                    <Suspense>
                        {Component && <Component siteConfig={siteConfig} moduleData={module}/>}
                    </Suspense>
                  </ErrorBoundary>  
                </div>
            )
            }) : <></> }
            {loading && <div className="module-loader-spinner"><Spinner /></div>}
            {hasLoaded && newModules.length ? <Modules  siteConfig={siteConfig} offset={offset + 4} moduleCount={props.moduleCount} pagedata={pagedata}/>:<></>}
            </>
        
    )
}

export default Modules
