
import pubsub from '@viewlift/pubsub';
import localforage from 'localforage';
import { fetchPageData } from './fetchPageData';
import jwtDecode from "jwt-decode";
import Cookies from 'js-cookie';
import axios from "axios";
import map from 'lodash/map';
import { fetchGameInsightData } from './fetchGameInsightData';
import { fetchMultiviewTrayData } from './fetchMultiviewTrayData';
import { fetchScheduleGameData } from "./fetchScheduleGamedata";
import { fetchSearchData } from './fetchSearchData';
import { ANALYTICS, FB_EVENTS, pixel_Events } from './helpers/analyticsMap';
import { fetchCategoryData } from './fetchCategoryData';
import { logout } from './MFEs/web-authentication-sdk/src/logic/login/logOut';
import { fetchFeaturedTrayData } from "./helpers/manageSSE";
import trackEvent from './helpers/trackAnalytics';
import { isMobile } from 'react-device-detect';
import { connectSSE } from './SSE';
import {subResourceIntegrity} from "./Graphql/hash"
import { fetchUserObject } from './MFEs/Monetization-Sdk/src/fetchStore';
import { parseJSON } from './MFEs/Monetization-Sdk/src/helper';
import { fetchReplaysData } from './fetchReplaysData';

const login = import('../client/MFEs/web-authentication-sdk/src/logic/login/login')
const us = import('./MFEs/web-authentication-sdk/src/updateStore');

var isTokenRefreshed = false

export function isValidJson(data) {
  try {
    JSON.parse(data);
    return true;
  } catch (e) {
    return false;
  }
}

export const VideoPlayerLocalisationData = () =>  {
  return { 
    relatedVideoTitle: window?.app_data?.appcmsMain?.genericMessages?.relatedVideoTitle || "Recommended",
    settingsTitle: window?.app_data?.appcmsMain?.genericMessages?.settingSectionTitle || "Setting",
    videoQualityText: window?.app_data?.appcmsMain?.genericMessages?.videoQualitySectionTitle || "Video Quality",
    audioLanguageText: window?.app_data?.appcmsMain?.genericMessages?.audioLanguage || "Audio",
    slowMoText: window?.app_data?.appcmsMain?.genericMessages?.slowMoCtaText || "Slow mo",
  }
};

export const checkForHBA = async () => {
    let onBoardingPages = window?.app_data?.appcmsPlatform.navigation.onBoarding;
    let isLocateAvailable = false;
    let userHasHBA = false
    onBoardingPages.forEach((page) => {
      if(page.title == "Locate") isLocateAvailable = true;
    })

    let userInfo = await localforage.getItem("AuthenticationStore")
    if(userInfo?.user?.hbaMetadata?.enabled){
      userHasHBA = true 
    }

    if(isLocateAvailable ){
      if(userHasHBA) return true
      return false
    }

    return true

}

export const pageClientQueryData = async (pageData,setPageData,abConfigData) => {
    window.countryCode = pageData?.countryCode	
   const authData = await localforage.getItem('AuthenticationStore')	
   const _t = Cookies.get("userStateToken") || authData?.user?.userState
    const userStateToken = isValidJson(_t) ? JSON.parse(_t) : _t
   let baseUrl = window?.app_data?.appcmsMain?.apiConfig?.apiBaseUrl		
   let endpoint = window?.cachedAPI || `${baseUrl}/graphql`
   let parsedCountryCode = pageData?.countryCode || "IN" //(countryCode && countryCode.country && (countryCode.country).toLowerCase()) || ""
   let offset = 0
   let variables = {
     site: (window?.app_data?.site?.siteInternalName) || null,
     device: "WEB",
     includeContent: true,
     moduleLimit: 4,
     moduleOffset: offset,
     languageCode: window?.app_data?.appcmsMain?.languages?.default?.code || "en",
     countryCode: parsedCountryCode,
     userState: authData && userStateToken ? userStateToken : window.btoa(JSON.stringify({ "state": ["loggedOut"] })),
     modules: [],
     platform : 'web_browser'
   }
   if (abConfigData?.status) variables["pageId"] = abConfigData?.pageId
   else variables["path"] = location?.pathname
   fetchPageData(variables,endpoint).then((data) => {
    if (data) {
      let page_data = { page: data.data.data["page"], path: location?.pathname };
      setPageData(page_data);
      if (typeof (window) !== undefined) {
        window.page_data = page_data;
      }
      fetchFeaturedTrayData(page_data)
      pubsub.publish('apploading', false);
      Cookies.set('apploading',false)
    }
    let abConfigPayload = abConfigData
    handlePageViewEvent(page_data,abConfigPayload)
  }).catch((e) => {
    pubsub.publish('apploading', false);
    Cookies.set('apploading',false)
  });
};
export const handlePageViewEvent = (pageData,abConfigPayload) => {
  var lastActivity = null
  if (document.referrer && document.referrer !== window.location.href) {
    lastActivity = document.referrer
  }
  let gaPageViewPayload = {
    page_title: (pageData.page && pageData.page.metadataMap && pageData.page.metadataMap.title) || "N/A",
    pageId: pageData?.page?.id,
    platform: 'Web'
  }
  let pageViewData =  {
    pageName: pageData?.page?.title,
    pageURL: window?.location?.href,
    lastActivityName: lastActivity,
    source: lastActivity,
    platform: 'Web'
  }
  if (abConfigPayload?.status) {
    gaPageViewPayload.pageId = abConfigPayload?.pageId,
    gaPageViewPayload.variationId = abConfigPayload?.variationId
    gaPageViewPayload.hash = abConfigPayload?.hashId
    pageViewData.variationId = abConfigPayload?.variationId
    pageViewData.hash = abConfigPayload?.hashId
    pageViewData.pageId = abConfigPayload?.pageId
  }

  trackEvent('page_view', gaPageViewPayload, null) 
  pubsub.publish("FB-analytics",{eventType :FB_EVENTS.PAGE_VIEW ,payload :pageViewData,type :'FB'})
  pubsub.publish("FB-analytics",{eventType :pixel_Events.PAGE_VIEW ,payload : pageViewData,type :'PIXEL'})
}
// export const fetchUserSubscription = async (userId, token) => {
//   return axios({
//     method: 'GET',
//     url: `${window?.apiBaseUrl}/identity/user`,
//     params: { site: window?.app_data?.site?.siteInternalName, userId: userId, platform: "web_browser" },
//     headers: {
//       Authorization: token,
//       'x-api-key': window?.xApiKey
//     }
//   })
//     .then((res) => {
//       return res;
//     }).catch((err) => {
//       let code = err?.response?.data?.code
//       let isLoggedOut = code === "UNAUTHORIZED" || code === "DEVICE_NOT_FOUND" || code === "REFRESH_TOKEN_NOT_FOUND"
//       if (isLoggedOut) fireLogout()
//     })
// };

export const fetchUserData = async (userId, token) => {
  return axios({
    method: 'GET',
    url: `${window?.apiBaseUrl}/identity/user`,
    params: { site: window?.app_data?.site?.siteInternalName, userId: userId, platform: "web_browser" },
    headers: {
      Authorization: token,
      'x-api-key': window?.xApiKey
    }
  })
    .then((res) => {
      localforage.getItem('AuthenticationStore').then((store) => {
        localforage.setItem('AuthenticationStore',{user: {...store?.user, ...res.data}})
      })
      // setting userState in cookies for personalized hompage modules rendered from servers side
      Cookies.set("userStateToken", JSON.stringify(res?.data?.userState))
      return res;
    }).catch((err) => {
      let code = err?.response?.data?.code
      let isLoggedOut = code === "UNAUTHORIZED" || code === "DEVICE_NOT_FOUND" || code === "REFRESH_TOKEN_NOT_FOUND"
      if (isLoggedOut) fireLogout()
    })
};
export function getQueryParams(qs) {
  let isAppAccessing;
  let paramString = qs?.split('?').length > 0 ? qs?.split('?')[1] : null;
  if (paramString) {
    let params_arr = paramString.split('&') || [paramString];

    for (let i = 0; i < params_arr.length; i++) {
      let pair = params_arr[i]?.split('=');
      if (pair.includes('app')) isAppAccessing = true;
      else isAppAccessing = false;
    }
  }
  return isAppAccessing;
}

export function listenToChannel(bc){
  bc.onmessage = (event) => {
    console.log(event);
    let isItHeartbeat  = event.heartbeat
    let currentTime = new Date().getTime().toString();
    sessionStorage.setItem('broadcast-heartbeat',currentTime)
    if(!isItHeartbeat) connectSSE(event)
  };
}

export const handleAppLogin = async () => {
  let isAppAccessing = getQueryParams(window.location.href);
  // const AuthenticationStore = await localforage.getItem('AuthenticationStore');
  if (isAppAccessing) {
    let userToken = Cookies.get('app-token');
    let userId = jwtDecode(userToken).userId;
    if (userToken) {
      let response = await fetchUserData(userId, userToken);
      localforage.setItem('AuthenticationStore', { user: response?.data });
    }
  }
};


let tokenData;
let loading;

function decodeToken(token) {
  var authToken = jwtDecode(token);
  var tokenExp = authToken.exp;
  var date = new Date(tokenExp * 1000);
  return date;
}

export function setToken(res) {
  // if (!res || !res.authorizationToken){
  //   throw(new Error('Invalid request to update token'))
  // }
  var tokenExpiryTime = decodeToken(res.authorizationToken);
  tokenData = {
    expiration: tokenExpiryTime.getTime(),
    authorizationToken: res.authorizationToken,
    refreshToken: res.refreshToken,
    duration: tokenExpiryTime.getTime() - new Date().getTime(),
  };
  Cookies.set('token', JSON.stringify(tokenData), { expires: 365 });
  return tokenData;
}

export const clearToken = (name) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC`;
}

const generateId = _ => {
    const s4 = _ => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1)
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
}

export const getDeviceId = () => {

  if(localStorage.getItem("deviceId")) return localStorage.getItem("deviceId")
  else{
    var deviceId = 'browser-' + generateId();
    localStorage.setItem("deviceId",deviceId)
    return deviceId
  }
  
}

export const getRefreshToken=()=>{
  let token = Cookies.get('token') && JSON.parse(Cookies.get('token'))
  return token?.refreshToken || null;
}

export async function getToken(cb) {
  let appToken = Cookies.get("app-token")
  if (appToken) {
    let appAuthorizationToken = parseJSON(appToken)
    if (appAuthorizationToken?.authorizationToken) {
      return appAuthorizationToken.authorizationToken
    }else {
      return appToken
    }
  }
  const AuthenticationStore = await localforage.getItem('AuthenticationStore')
  let user = AuthenticationStore?.user
  let userData = user ? user : {}
  let token = Cookies.get('token') && JSON.parse(Cookies.get('token'))
    
  return new Promise((resolve, reject) => {

    if(window.fetchToken){
      setTimeout(() => {
        getToken(cb)
      },500)
    }
    if (token?.authorizationToken  || user?.authorizationToken) {
      let currentToken = token?.authorizationToken  || user?.authorizationToken
      var tokenExpiryTime = decodeToken(currentToken)
      var now = new Date().getTime();
      if (now < tokenExpiryTime) {
        if (cb) cb(currentToken)
        resolve(currentToken)
        window.fetchToken = false
        return
      }
    }
    if ((token?.refreshToken || tokenData?.refreshToken || (userData && Object.keys(userData).length > 0) && !window.fetchingRefreshToken) && !isTokenRefreshed) {
      console.log("called from client helper refresh inside", isTokenRefreshed)
      isTokenRefreshed = true
      // console.log('refreshing token')
      window.fetchingRefreshToken = true
      axios({
        method: 'GET',
        url: `${ window?.app_data?.appcmsMain?.apiConfig?.apiBaseUrl }/identity/refresh/${token?.refreshToken || userData?.refreshToken }`,
      }).then( res => {
        var d = res.data && {
          authorizationToken: res.data.authorizationToken,
          refreshToken: res.data.refreshToken
        }
        setToken(d)
        localforage.setItem('AuthenticationStore',{user:{...user,...d}})
        if (cb) cb(d?.authorizationToken, d?.refreshToken)
        window.fetchToken = false
        window.fetchingRefreshToken = false
        isTokenRefreshed = false
        resolve(d.authorizationToken, d?.refreshToken)
      }).catch(err => {
        window.fetchToken = false
        console.error('Error refreshing token', err)
        window.fetchingRefreshToken = false
        isTokenRefreshed = false
        localforage.clear();
        sessionStorage.removeItem("token")
        clearToken('token')
        if(err?.response?.data?.code ===  "REFRESH_TOKEN_NOT_FOUND"){
          setTimeout(() => {
            window.location.assign("/");
          }, 2000);
        }else{
          window.location.assign("/");
        }
        // clearToken()
        // reject(err)
      })
      return
    }

    // If user, remove user
    var url = `${ window?.app_data?.appcmsMain?.apiConfig?.apiBaseUrl}/identity/anonymous-token`
    

    // Fetch token
    if(!window.fetchToken && !isTokenRefreshed){

      var deviceId = getDeviceId();

      axios({
        method: "GET",
        url: url,
        params: {
           site: window?.app_data?.site?.siteInternalName,
           platform : "web_browser",
           deviceId: deviceId
           },
        headers: {
          "x-api-key": window?.xApiKey
        }
      })
      .then(res => {
        var d = res.data && { authorizationToken: res.data.authorizationToken };
        setToken(d);
        window.fetchToken= false
        if (cb) cb(d.authorizationToken, d.refreshToken);
        resolve(d.authorizationToken, d.refreshToken);
      })
      .catch(err => {
        window.fetchToken = false
        console.error("Error fetching anonymous token", err);
        pubsub.publish('isLoading',false)
        reject(err);
      });
    }
  })
}

export const updateSseStore = (data) => {
  let store = JSON.parse(localStorage.getItem('SseStore'))
    let sseStore;
    if (store) {
      if (store[Object.keys(data)[0]]) {
        let prevData = store[Object.keys(data)[0]];
        let newData = {
          ...prevData,
          ...Object.values(data)[0]
        };
        store[Object.keys(data)[0]] = newData;
        sseStore = { ...store };
      } else {
        sseStore = { ...store, ...data };
      }
    } else {
      sseStore = { ...data };
    }
    localStorage.setItem('SseStore', JSON.stringify(sseStore));
    window[Object.keys(data)[0]] = {
      ...window[Object.keys(data)[0]],
      ...Object.values(data)[0]
    }
    pubsub.publish(Object.keys(data)[0], Object.values(data)[0]);
  
};

export const updateLeaderboardSse = (data) => {
  let store = JSON.parse(localStorage.getItem('leaderboardSseStore'))
  let leaderboardSseStore
  if (store) {
    leaderboardSseStore = {...store, ...data}
  }else {
    leaderboardSseStore = {...data}
  }
  localStorage.setItem('leaderboardSseStore', JSON.stringify(leaderboardSseStore));
  pubsub.publish(Object.keys(data)[0], Object.values(data)[0])
}

export const updateGraphicsData = (data) => {
  let store = JSON.parse(localStorage.getItem('playerGraphicsStore'))
  
  let playerGraphicsStore
  if (store) {
    playerGraphicsStore = {...store, ...data}
  }else {
    playerGraphicsStore = {...data}
  }
  localStorage.setItem('playerGraphicsStore', JSON.stringify(playerGraphicsStore));
}



export const updatePlayerNextStrokeSSE = (data) => {
  let store = JSON.parse(localStorage.getItem('playerNextStrokeSSEStore'))
  let playerNextStrokeSSEStore
  if (store) {
    playerNextStrokeSSEStore = {...store, ...data}
  }else {
    playerNextStrokeSSEStore = {...data}
  }
  localStorage.setItem('playerNextStrokeSSEStore', JSON.stringify(playerNextStrokeSSEStore));
  pubsub.publish('STREAM_SWITCH', Object.values(data)[0])
}

export const getTeamTitles = (teams) => {
  let teamsTitles = {};
  let homeTeamShortName = teams?.homeTeam?.shortName;
  let awayTeamShortName = teams?.awayTeam?.shortName;
  if (homeTeamShortName) teamsTitles.homeTeamTitle = homeTeamShortName;
  else {
    let title = teams?.homeTeam?.gist?.title.split(" ");
    teamsTitles.homeTeamTitle = title && title[0];
  }
  if (awayTeamShortName) teamsTitles.awayTeamTitle = awayTeamShortName;
  else {
    let title = teams?.awayTeam?.gist?.title.split(" ");
    teamsTitles.awayTeamTitle = title && title[0];
  }
  return teamsTitles;
};

export const gameInsightQueryData = async (league, srGameId) => {
  const apiBaseUrl = window?.app_data?.appcmsMain?.apiConfig?.apiBaseUrl;
  let endpoint = `${apiBaseUrl}/graphql`;
  let variables = { 
    site: window?.app_data?.site?.siteInternalName,
    league: league,
    srGameId: srGameId,
    srPlayerId: null,
    srTeamId: null,
    offset: null
  };
  return fetchGameInsightData(variables, endpoint);
};

export const multiviewTrayQueryData = async (gameId, ids=[], limit=10, offset=0) => {
  const apiBaseUrl = window?.app_data?.appcmsMain?.apiConfig?.apiBaseUrl;
  let endpoint = `${apiBaseUrl}/graphql`;
  let variables = { 
    site: window?.app_data?.site?.siteInternalName, 
    ids: ids,
    contentType: "COLLECTION",
    gameId: gameId,
    countryCode: window?.app_data?.countryCode,
    languageCode: window?.app_data?.appcmsMain?.languages?.default?.code || "en",
    limit: limit,
    offset: offset
  };
  return fetchMultiviewTrayData(variables, endpoint);
};

export const categoryQueryData = async (path, contentType, nextValue, modules, offset) => {
  const apiBaseUrl = window?.app_data?.appcmsMain?.apiConfig?.apiBaseUrl;
  let endpoint = `${apiBaseUrl}/graphql`;
  let variables = {
    site: window?.app_data?.site?.siteInternalName,
    path: path,
    device: 'WEB',
    includeContent: true,
    languageCode: window?.app_data?.appcmsMain?.languages?.default?.code || "en",
    countryCode: window?.page_data?.countryCode,
    contentType: contentType,
    next: nextValue,
    modules: modules
  };
  return fetchCategoryData(variables, endpoint);
};

export const replayQueryData = async (path, contentType, nextValue, modules) => {
  const apiBaseUrl = window?.app_data?.appcmsMain?.apiConfig?.apiBaseUrl;
  let endpoint = `${apiBaseUrl}/graphql`;
  let variables = {
    site: window?.app_data?.site?.siteInternalName,
    path: path,
    device: 'WEB',
    includeContent: true,
    languageCode: window?.app_data?.appcmsMain?.languages?.default?.code || "en",
    countryCode: window?.page_data?.countryCode,
    contentType: contentType,
    next: nextValue,
    modules: modules
  };
  return fetchReplaysData(variables, endpoint);
};

export const scheduleGameQueryData = async (startDate, endDate, teams = [],source, teamIds) => {
  const apiBaseUrl = window?.app_data?.appcmsMain?.apiConfig?.apiBaseUrl;
  let baseUrl = window?.app_data?.appcmsMain?.apiConfig?.apiBaseUrl		
  let endpoint = window?.cachedAPI || `${baseUrl}/graphql`
  let variables = {
    site: window?.app_data?.site?.siteInternalName,
    limit: 50,
    offset: 0,
    startDate: startDate,
    endDate: endDate,
    sortBy: null,
    sortOrder: null,
    countryCode: window?.app_data?.countryCode,
    teamIds: teamIds,
    source:source,
    ...(teams.length > 0 && { teamIds: teams })
  };
  return fetchScheduleGameData(variables, endpoint);
};

export const addLikeContentById = async (payload) => {
  const apiBaseUrl = window?.app_data?.appcmsMain?.apiConfig?.apiBaseUrl;
  let endpoint = `${apiBaseUrl}/v2/user/like`;
  const token = await getToken();
  let headers = {
    headers: { 'Authorization': token }
  };
  return axios.post(endpoint, payload, headers);
};

export const removeLikeContentById = async (contentId, contentType) => {
  const apiBaseUrl = window?.app_data?.appcmsMain?.apiConfig?.apiBaseUrl;
  let endpoint = `${apiBaseUrl}/v2/user/like?contentId=${contentId}&contentType=${contentType}`;
  const token = await getToken();
  let headers = {
    headers: { 'Authorization': token }
  };
  return axios.delete(endpoint, headers);
};

export const addWatchListContentById = async (payload) => {
  const apiBaseUrl = window?.app_data?.appcmsMain?.apiConfig?.apiBaseUrl;
  const profile = await localforage.getItem('AuthenticationStore')
  const profileId = profile?.user?.profiles[0].profileId
  let endpoint = `${apiBaseUrl}/v2/user/queues?profileId=${profileId}`;
  const token = await getToken();
  let headers = {
    headers: { 'Authorization': token }
  };
  return axios.post(endpoint, payload, headers);
};

export const removeWatchListContentById = async (contentId, contentType) => {
  const apiBaseUrl = window?.app_data?.appcmsMain?.apiConfig?.apiBaseUrl;  
  const profile = await localforage.getItem('AuthenticationStore')
  const profileId = profile?.user?.profiles[0].profileId
  let endpoint = `${apiBaseUrl}/v2/user/queues?contentId=${contentId}&contentType=${contentType}&profileId=${profileId}`;
  const token = await getToken();
  let headers = {
    headers: { 'Authorization': token }
  };
  return axios.delete(endpoint, headers);
};

const watchListAnalytics=(watchlistData)=>{
  let arr=watchlistData.map((ele)=>{
    return ele.content.id;
  })
  pubsub.publish('user-analytics',{ename:ANALYTICS.REMOVE_ALL_WATCHLIST,data:{items:arr}})
}

const watchHistoryAnalytics=(watchHistoryData)=>{
  let arr=watchHistoryData?.map((ele)=>{
    return ele.content.id;
  })
  pubsub.publish('user-analytics',{ename:ANALYTICS.REMOVE_ALL_WATCHHISTORY,data:{items:arr}});
}

export const removeAllWatchList = async (data) => {
  const apiBaseUrl = window?.app_data?.appcmsMain?.apiConfig?.apiBaseUrl;  
  const profile = await localforage.getItem('AuthenticationStore')
  const profileId = profile?.user?.profiles[0].profileId
  let endpoint = `${apiBaseUrl}/v2/user/queues?contentId=ALL&profileId=${profileId}`;
  const token = await getToken();
  let headers = {
    headers: { 'Authorization': token }
  };
  return axios.delete(endpoint, headers).then((res)=>{
    watchListAnalytics(data);
  });
};

export const getUserWatchHistory = async (offset) => {
  let historyOffset = offset || 0
  const apiBaseUrl = window?.app_data?.appcmsMain?.apiConfig?.apiBaseUrl;
  let endpoint = `${apiBaseUrl}/v2/user/history?limit=8&offset=${historyOffset}`;
  const token = await getToken();
  let headers = {
    headers: { 'Authorization': token }
  };
  return axios.get(endpoint, headers);
};

export const removeUserWatchHistoryById = async (videoId,data) => {
  const apiBaseUrl = window?.app_data?.appcmsMain?.apiConfig?.apiBaseUrl;
  let endpoint = `${apiBaseUrl}/v2/user/history?videoId=${videoId}`;
  const token = await getToken();
  let headers = {
    headers: { 'Authorization': token }
  };
  return axios.delete(endpoint, headers).then((res)=>{
    watchHistoryAnalytics(data);
  });
};


export const getUserWatchList = async () => {
  const apiBaseUrl = window?.app_data?.appcmsMain?.apiConfig?.apiBaseUrl;
  const profile = await localforage.getItem('AuthenticationStore')
  const profileId = profile?.user?.profiles[0].profileId
  let endpoint = `${apiBaseUrl}/v2/user/queues?profileId=${profileId}`;
  const token = await getToken();
  let headers = {
    headers: { 'Authorization': token }
  };
  return axios.get(endpoint, headers);
};

export const removeUserWatchlistById = async (contentId, contentType) => {
  const apiBaseUrl = window?.app_data?.appcmsMain?.apiConfig?.apiBaseUrl;
  const profile = await localforage.getItem('AuthenticationStore')
  const profileId = profile?.user?.profiles[0].profileId
  let endpoint = `${apiBaseUrl}/v2/user/queues?contentId=${contentId}&contentType=${contentType}&profileId=${profileId}`;
  const token = await getToken();
  let headers = {
    headers: { 'Authorization': token }
  };
  return axios.delete(endpoint, headers);
};

export const getUserConetntInfo = async (contentType, contentId) => {
  const apiBaseUrl =window?.app_data?.appcmsMain?.apiConfig?.apiBaseUrl;
  const profile = await localforage.getItem('AuthenticationStore')
  const profileId = profile?.user?.profiles?.length && profile?.user?.profiles[0].profileId
  let endpoint = `${apiBaseUrl}/v2/user/info/${contentType}/${contentId}?profileId=${profileId}`;
  const token = await getToken();
  let headers = {
    headers: { 'Authorization': token }
  };
  return axios.get(endpoint, headers);
};
/**
 * 
 * @param {import('./types/StandaloneSearchTypes').SearchParamsType} params?
 * @returns 
 */
export const searchResultData = async (params) => {
  const apiBaseUrl = window?.app_data?.appcmsMain?.apiConfig?.apiBaseUrl;
  // if (!params.key) return
  let endpoint = window?.cachedAPI || `${apiBaseUrl}/graphql`;
  let variables =
  {
    languageCode: window?.app_data?.appcmsMain?.languages?.default?.code || "en",
    countryCode: window?.app_data?.countryCode || "IN",
    keyword: params.key,
    site: (window?.app_data?.site?.siteInternalName) || null,
    userState: "",
    limit: 100,
    offset: 0,
    relatedSearch: true,
    types: params.type || []
  };
  return fetchSearchData(variables, endpoint);
};

export const switchBetweenPages = () => {
  pubsub.publish('apploading', true);
  handleNewTabLoader()
  return true
};

export const handleNewTabLoader = () => {
  Cookies.set('apploading',true)
  setTimeout(() => {
    let appLoading = Cookies.get('apploading')
    if (appLoading) {
      Cookies.set('apploading',false)
      pubsub.publish('apploading', false);
    }
  },3000)
}
export const handleImageImpolicy = (src , imWidth, imHeight) => {
  if (src.includes("impolicy")) {
    let srcSplit = src.split("impolicy")
    let newSrc = `${srcSplit[0]}impolicy=resize&w=${imWidth}&h=${imHeight}`
    // newSrc[1] = `impolicy=resize&w=${imWidth}&h=${imHeight}`
    // newSrc.join(``)
    return newSrc
  }else {
    return `${src}?impolicy=resize&w=${imWidth}&h=${imHeight}`
  }
}

export const getAppDataConfig = () => {
  const appData =  window?.app_data?.appcmsMain?.firebaseConfig
  return appData
}

export const listenDisposeCookies = () => {
  pubsub.subscribe('dispose-cookies', (cookie) => {
    Cookies.remove(cookie);
})}

export const handleUserLogInState = async () => {
  let state = {
    isUserLoggedIn: false,
    isUserSubscribed: false,
  };
  const AuthenticationStore = await localforage.getItem('AuthenticationStore')
  let user = AuthenticationStore?.user
  let istveSubscribed = user?.tveProvider
  if (user?.userId) state = { ...state, isUserLoggedIn: true };
  if (user?.isSubscribed || istveSubscribed) state = { ...state, isUserSubscribed: true };
  localStorage.setItem('loginState',JSON.stringify(state))
};
export const fireLogout = () => {
  logout().then((res) => {
      localforage.clear();
      localStorage.removeItem('dismissSplash')
      Cookies.remove('userStateToken');
      localStorage.removeItem('pageRefreshStatus');
      localStorage.removeItem('hidePersonalizationBanner');
      pubsub.publish('dispose-cookies','token')
      pubsub.publish('apploading',false)
      window?.AE && window?.AE.logout()
      window?.location.assign("/");
      return res.data;
    })
    .catch((e) => {
      console.error(e);
      pubsub.publish('apploading',false)
      return e;
    });
};

export const tveSignOut = async () => {
  let apiBaseUrl = window?.app_data?.appcmsMain?.apiConfig?.apiBaseUrl
   let token = await getToken((token) => {return token})
   const options = {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      'Authorization': token
    }
  };
  
  return fetch(`${apiBaseUrl}/identity/tveSignout`, options)
    .then(response => response.json())
}

const handleDeviceRemoved = (data) => {
  let deviceIds = data?.deviceIds
  let currdeviceId = localStorage.getItem('deviceId')
  if (deviceIds.includes(currdeviceId) || deviceIds[0] === "ALL") fireLogout()
}

export const listenDeviceRemoved = () => {
  pubsub.subscribe('DEVICE_REMOVED',handleDeviceRemoved)
} 
// export const listenAutoLogout = async () => {
//   const AuthenticationStore = await localforage.getItem('AuthenticationStore')
//   if (!AuthenticationStore) return
//   let userId = AuthenticationStore?.user?.userId
//   if (userId) {
//     console.log("working listenDeviceRemoved")
//     let currentTime = new Date().getTime()
//     let targetTime = await localforage.getItem('login-device-time')
//     let targetTimeDate = new Date(currentTime).getHours() - new Date(targetTime).getHours()
//     if (targetTimeDate > 5 && targetTime) {
//       let token = await getToken(token => {return token})
//       fetchUserData(userId,token)
//     }else {
//       if (!targetTime) localforage.setItem('login-device-time',currentTime)
//     }
//   }
// }

export const handleSeviceTypes = () => {
  let serviceTypes = {
    SVOD : false,
    TVOD : false,
    TVE : false,
    AVOD : false,
    FREE_LOGIN: false,
    EMAIL_REQUIRED: false
  }
  let services = window?.app_data?.appcmsMain?.serviceTypes || []
  if (services.includes("SVOD")) serviceTypes.SVOD = true
  if (services.includes("TVOD")) serviceTypes.TVOD = true
  if (services.includes("TVE")) serviceTypes.TVE = true
  if (services.includes("AVOD")) serviceTypes.AVOD = true
  return serviceTypes
}

export const checkVppaCompliance = () => {
  let vppaEnabled = window?.app_data?.site?.settings?.compliance?.vppa
  let UserJourney = window?.location?.pathname

  localforage.getItem('AuthenticationStore')
  .then((store) => {
    if(store && store.user.userId && vppaEnabled && !store?.user?.vppaCompliance && UserJourney != '/consent' && location){
        if(location.pathname == '/tos' && location.pathname == '/privacy-policy' && location.pathname == '/agreement' && location.pathname == '/offers'){
          return
        }
        location.href = '/consent'
      }
    })
  }        
function copyScriptsInHtml(htmlNode) {
  const scriptTags = htmlNode.getElementsByTagName("vlscript")

  return  map(scriptTags, tag => {
    if (tag) {
      let newTag = document.createElement("script")
      if (tag.hasAttributes()) {
        for (const attr of tag.attributes) {
          newTag.setAttribute(attr.name, attr.value);
        }
      }
      
      tag.textContent && (newTag.textContent = tag.textContent)
      return newTag
    }
  })
}

export const updateVppaStatus = (userID,token,consent) => {

  return axios({
    method: 'PUT',
    url: `${window?.app_data?.appcmsMain?.apiConfig?.apiBaseUrl}/v2/identity/${userID}`,
    headers: {
      Authorization: token,
    },
    data:{
      "vppaCompliance":consent
    }
  })
    .then((res) => {
      return res;
    }).catch((err) => {
      let code = err?.response?.data?.code
      fireLogout()
    })
}

export const removeAlldevices = (token) => {
  return axios({
    method: 'DELETE',
    url: `${apiBaseUrl}/user/device/desync`,
    headers: { 
      'Authorization': token,
      'x-api-key': window?.xApiKey
    },
    params: {
      site: window?.app_data?.site?.siteInternalName,
    }
  })
}
export const rejectAndLogout = async () => {
  pubsub.publish("apploading",true)

  getToken((token) => {
      removeAlldevices(token)
      .then(() => {
          fireLogout()
      })
      .catch(() => {
          console.log("failed to remove devices ")
          pubsub.publish('apploading',false)
      })
  })

}
  
export const embedCode = () => {
  if (typeof window !== undefined) {
    let scripted = window?.app_data?.appcmsMain.features.embeddedCode;
    if (scripted) {
      scripted = scripted.replace(/(\r\n|\n|\r)/gm, "");
      const html = document.createElement("div");
      html.insertAdjacentHTML('beforeend', scripted);
      let gtmscript = copyScriptsInHtml(html);
      gtmscript.forEach((script) => {
        if(script?.innerText){
          script?.setAttribute("integrity", subResourceIntegrity(script?.innerText));
        }
        document.body.appendChild(script);
      });
    }
  }
};

export const initGTM = () => {
  let gtmId = window?.app_data?.appcmsPlatform?.analytics?.googleTagManagerId || null
  if (!gtmId)
    return
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!=='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window, window.document,'script','dataLayer', gtmId);
}

export const pushToDatalayer = (key,value) => {
  if(window?.dataLayer) {
      let newValue  = {}
      newValue[key] = value;
      window?.dataLayer.push(newValue);
  }
}

export const insertCookieBanner = () => {
  let cdnUrl = window?.app_data?.appcmsMain?.privacy?.oneTrust?.cdnUrl
  let clientID = window?.app_data?.appcmsMain?.privacy?.oneTrust?.clientId
  if(!cdnUrl && !clientID) return 
  const script = document.createElement('script');
  script.src = cdnUrl;
  script.type = 'text/javascript';
  script.setAttribute('data-domain-script', clientID);
  document.head.appendChild(script);
  const functionScript = document.createElement('script');
  functionScript.type = 'text/javascript';
  functionScript.text = function OptanonWrapper() { }
  document.head.appendChild(functionScript);
}
export const getTVProviderLogo=(provider)=>{
  let providers=window.app_data.appcmsPlatform.tveSettings.providers || [];
  let currentProvider=providers.filter((ele)=>{
    if(ele.identifier==provider)
    return ele?.logoUrl;
  })
  return currentProvider?.[0]?.logoUrl || null;
}

export const handleUserLogout = async (userId) => {
  if (userId) {
    let token = await getToken(token => {return token})
    fetchUserData(userId,token)
  }
}

export const getTVProvider=async()=>{
  const store = await localforage.getItem('AuthenticationStore')
  return store?.user?.tveMetadata?.idpName || null 
}

export const getFwTveProvider=async()=>{
  const store = await localforage.getItem('AuthenticationStore')
  let mvpdID = store?.user?.tveMetadata?.idpName || null
  if(!mvpdID) return null
  return extractFreeWheelId(mvpdID) ;
}

const extractFreeWheelId = (mvpdID) => {
  let providers = window.app_data.appcmsPlatform.tveSettings.providers
  let selectedProvider = providers.find((provider) => {
    if(provider.identifier === mvpdID) return true
  })

  return selectedProvider.fwIdentifier || null
}

export const getUserSubscription=async()=>{
  const store = await localforage.getItem('AuthenticationStore');
  let SVOD = (store && store?.user?.isSubscribed && !store?.user?.tveUserId) || false;
  let TVE = (store && !store?.user?.isSubscribed && store?.user?.tveProvider) || false;
  return {
    SVOD: SVOD,
    TVE:TVE
  }
}
export const getMacrosFromMetaData = async (metadata) => {
  let retObj = {};
  const store = await localforage.getItem('AuthenticationStore');
  let macroMap = new Map();
  metadata?.length && metadata.map((ele) => {
    macroMap.set(ele.name, ele.value);
  })

  let SVOD = (store && store?.user?.isSubscribed && !store?.user?.tveUserId) || false;
  let TVE = (store && !store?.user?.isSubscribed && store?.user?.tveProvider) || false;

  // CSID
  if (isMobile){
    if (SVOD)
    retObj = { ...retObj, VIEWLIFT_CSID: macroMap.get('csid.mweb.dtc') || '' };
    
    else if(TVE)
    retObj = { ...retObj, VIEWLIFT_CSID: macroMap.get('csid.mweb.tve') || '' };
  }
  else{
    if (SVOD)
    retObj = { ...retObj, VIEWLIFT_CSID: macroMap.get('csid.web.dtc') || '' };
    
    else if(TVE)
    retObj = { ...retObj, VIEWLIFT_CSID: macroMap.get('csid.web.tve') || '' };
  }

  // SFID
  if (SVOD)
    retObj = { ...retObj, VIEWLIFT_SFID: macroMap.get('sfid.dtc') || '' };

  else if(TVE)
    retObj = { ...retObj, VIEWLIFT_SFID: macroMap.get('sfid.tve') || '' };

  // PROF
  if(isMobile)
  retObj = { ...retObj, VIEWLIFT_PROF: macroMap.get('prof.mweb') || '' };
  else
  retObj = { ...retObj, VIEWLIFT_PROF: macroMap.get('prof.web') || '' };

  return retObj;
}
/**
 * 
 * @param {String} value 
   * @returns {String} sanitized string 
 */
export const sanitizeInputValue = (value) => {
   const pattern = /(function\s*\([^)]*\)|\(\s*[^)]*\)\s*=>)\s*{[^}]*}|<[^>]*>/g
   return value.replace(pattern, "");
}
/**
 * @param {{coppa: boolean, gdpr: boolean, vppa: boolean}} compliance 
 * @returns {string[]} array of flow steps 
 */
export function getFlowSteps(compliance) {
  const onboardingFlow =  app_data?.appcmsPlatform?.navigation?.onBoarding || []
  const flowUrls = onboardingFlow.map(item => item.url)
  // add consent step if vppa is enabled and not added in onboarding flow
  if (compliance.vppa && !flowUrls.includes("/consent")) flowUrls.splice(1, 0, '/consent')
  // remove consent step if vppa is disabled and added in onboarding flow
  if (!compliance.vppa && flowUrls.includes("/consent")) flowUrls.filter(url => url !== "/consent")
  if(window?.app_data?.monetisationModelByCountry !== "SVOD") {
    if(flowUrls.includes("/viewplans")) flowUrls.splice(flowUrls.indexOf('/viewplans'), 1); 
    if(flowUrls.includes("/subscription")) flowUrls.splice(flowUrls.indexOf('/subscription'), 1);
  }
  return flowUrls
}

export const fetchUserDetails = async () => {
  const AuthenticationStore = await localforage.getItem('AuthenticationStore')
  if (!AuthenticationStore) return {}
  let user = AuthenticationStore && AuthenticationStore.user
  let token = await getToken(token => {return token})
  let profileId=AuthenticationStore.user?.profiles?.length && AuthenticationStore.user?.profiles[0]?.profileId
  let hba = AuthenticationStore?.user?.hbaMetadata
  const subscribedPlanId = AuthenticationStore?.user?.subscription?.subscriptionInfo?.planId
  return {token : token, userId : user?.userId,profileId:profileId, hba, subscribedPlanId, isSubscribed : user?.isSubscribed, user}
} 

export const handleUserTransactions = async () => {
  const token = await getToken();
  axios({
    method: 'GET',
    url: `${apiBaseUrl}/v3/transaction`,
    headers: {
      "Authorization": token
    }
  }).then(async res => {
    let transactionUserList = await localforage.getItem('transactionUserList')
    let list = []
    res?.data?.records?.length && res.data.records.map((content) => {
      let contentId = content?.contentId
      let itemIds = content?.itemIds
      if (contentId) list.push(contentId)
      if (itemIds?.length) list.push(...itemIds)
    })
    if (transactionUserList?.length) {
      list = [...transactionUserList, ...list]
    }
      localforage.setItem('transactionUserList',{transactionUserList: list})
   })
}

export const checkIfOnlyAudio=(metadata)=>{
  let isAudio=false;
  for(let i=0;i<metadata?.length;i++){
    if(metadata?.[i]?.name=='audio-only' && metadata?.[i]?.value=='true'){
      isAudio=true;
      break;
    }
  }
  return isAudio || false;
}

export function handleQueryUrls() {
  // qs window.location.href
  let qs = window.location.href
  // let queryEnabledUrl = {isManageCard : false, isUpgradeSubscription : false}
  let paramString = qs?.split('?').length > 0 ? qs?.split('?')[1] : null
  if (paramString) {
    let params_arr = paramString.split('&') || [paramString];
    for (let i = 0; i < params_arr.length; i++) {
     let pair = params_arr[i]?.split('=');
     localforage.getItem('AuthenticationStore').then(async (store) => {
      let user = store?.user
      if (!user) {
        if (pair.includes('managecard')) {
          localStorage.setItem('updateCardStatus',JSON.stringify(true))
        }
        if (pair.includes('upgradeplan')) {
          let isRedirected = await localforage?.getItem("isRedirected")
          localforage.setItem('isRedirected', {...isRedirected, isUpgradePlan:true, path: "viewplans?upgradeplan=true"})
          pubsub.publish('redirect',"/authentication")
        }
        return
      }
      if (user?.isSubscribed) {
        if (pair.includes('managecard')) {
          sessionStorage.setItem('updateCardStatus',JSON.stringify(true))
        }
        if (pair.includes('upgradeplan')) {
          let isRedirected = await localforage?.getItem("isRedirected")
          localforage.setItem('isRedirected', {...isRedirected, isUpgradePlan:true, path: "/"})
        }
      }else {
        if (pair.includes('managecard')) {
          localStorage.setItem('updateCardStatus',JSON.stringify(false))
          pubsub.publish('redirect',"/")
        }
      }
    })
    }
  }
}

export const handlePermalink = (pageType) => {
  if(typeof window !== "undefined"){
    let permalink = '';
    if (pageType == "content") {
      window?.page_data?.page.modules.forEach((module) => {
        if (module.moduleType.includes('DetailModule')) {
          let contentData = module.contentData[0];
          let contentType = contentData.gist.contentType.toLowerCase();
          permalink = `${window.toolsUrl}/content/${contentType}/${contentData.gist.id}`
        }
      })
    } else {
      permalink = window.templateUrl + '?isRedirectedFromWebApp=true&pageId=' + window?.page_data?.page.id
    }

    return permalink;
  }
  return ''
}

export async function verifyMagicLink() {
  if (typeof window === "undefined") return;
  let queryString = window.location.search;
  let urlParams = new URLSearchParams(queryString);
  const authToken = urlParams.get("auth");
  const userEmail = urlParams.get("email");
  const userTveCode = urlParams.get("tveCode");
  const userPhoneNumber = urlParams.get("phoneNumber");
  const user = await fetchUserObject()
  sessionStorage.removeItem('tveAuthenticated')
  if (!authToken) return;

  if (authToken && !userEmail && !userTveCode) {
    sessionStorage.setItem('authRedirectFlag', 'true'); 
    pubsub.publish("apploading", true);
    login.then((m) =>
      m
        .login({ auth: authToken }, undefined, "validate")
        .then(async (response) => {
          let res = response?.identityAuthOtpValidate
          us.then((m) =>
            m.updateAuthorisationStore({ userdetails: res }).then((u) => {
              if (res.isSubscribed) pubsub.publish("user", { ...res, provider: "EMAIL" });
              else pubsub.publish("user", { isSubscribed: false, provider: "EMAIL" });
              pubsub.publish("apploading", false);
              let reloadPath = (window.location.origin + window.location.pathname)
              if (u) window.location.href = reloadPath
            })
          );
        })
        .catch(console.error)
    );
  }

  if ((authToken && (userEmail || userPhoneNumber) && userTveCode) || (authToken && (userEmail || userPhoneNumber))){
    if(user){
      if((userEmail == String(user?.email)) || (user?.phoneNumber && user?.phoneNumber.includes(userPhoneNumber.trim()))){
        sessionStorage.setItem('tveAuthenticated', JSON.stringify({email: userEmail, phone: userPhoneNumber, tveCode: userTveCode}))
      }else{
        sessionStorage.setItem('tveAuthenticated', JSON.stringify({isUserValid: false, email: userEmail, phone: userPhoneNumber}))
      }
    }else{
      login.then((m) =>
        m
          .login({ auth: authToken }, undefined, "validate")
          .then(async (response) => {
            let res = response?.identityAuthOtpValidate
            us.then((m) =>
              m.updateAuthorisationStore({ userdetails: res }).then((u) => {
                if (res.isSubscribed) pubsub.publish("user", { ...res, provider: "EMAIL" });
                else pubsub.publish("user", { isSubscribed: false, provider: "EMAIL" });
                pubsub.publish("apploading", false);
                sessionStorage.setItem('tveAuthenticated', JSON.stringify({email: userEmail, tveCode: userTveCode, phone: userPhoneNumber}))
                pubsub.publish("user", { tveMagicLinkSuccess: true, email: userEmail, tveCode: userTveCode, phone: userPhoneNumber });
              })
            );
          })
          .catch(console.error)
      );
    }
  }
}


export const fetchPublicKey = () => {
  const publicKey = window?.app_data?.appcmsMain?.publicKey
  const key = axios.get(publicKey)
  return key
}
export const getFaqLink = () => {
  const apiBaseUrl = window.app_data?.appcmsMain?.apiBaseUrl;
  const footerLinks = window.app_data?.appcmsPlatform?.navigation?.footer
  const faqLinkObj = footerLinks?.filter((element) => element?.title?.toLowerCase()?.includes('faq'))
  const faqLinkUrl = (faqLinkObj && faqLinkObj[0]?.url) || null
  const faqLink = faqLinkUrl?.toLowerCase()?.includes('https://') ? faqLinkUrl : (apiBaseUrl + faqLinkUrl)
  return faqLink
}

export const isCheckoutFlow = () => {
  const compliance = window?.app_data?.site?.settings?.compliance;
  let flowUrls = getFlowSteps(compliance)
  if (flowUrls?.includes("checkout")) {
      return true
  }else {
      return false
  }
}
export const fillDelimeter = (text, deli, accessPath,) => {
  const formatName = name => name.replace(/-/g, ' ').replace(/\b\w/g, char => char.toUpperCase()) || '';
  const newName = formatName(accessPath);
  return text?.replace(deli, newName)
};
const fetchCurrentPlan = () => {
  let currentStoredPlan = parseJSON(sessionStorage.getItem('currentPlan'))
  if (currentStoredPlan) return currentStoredPlan
  return false
}
export const handleStatusGaCalls = async ({type,reason,status,action,paymentStatus,eventCategory,subSection,eventName,section,additionalLabel}) => {
  //reason - eventLabel1
  //status - eventLabel1
  // move to switch case
  if (eventName == 'checkout_init' ) {
    sessionStorage.setItem('checkout-initiate',JSON.stringify(true))
  }else if (eventName == "checkout_cancellation") {
    sessionStorage.setItem('checkout-initiate',JSON.stringify(false))
  }else if (eventName == 'checkout_success') {
    sessionStorage.setItem('checkout-initiate',JSON.stringify(false))
  }
  let userLocation = parseJSON(localStorage.getItem('locationCoords'))
  let event_name = eventName
  let userData = await fetchUserObject()
  let plan = fetchCurrentPlan()
  let payload = {
    event_label1 : reason ? reason : null,
    event_label2 : status ? status : null,
    logged_in_status : userData?.userId ? 'Yes'  : 'No',
    subscribed_status : userData?.isSubscribed ? 'Yes' : 'No',
    email : userData?.email,
    mobile : userData?.mobile,
    last_transaction_amount : plan?.amount,
    subscription_plan : plan?.title,
    user_location : userLocation ? userLocation : null,
    event_category : eventCategory,
    event_action : action,
    event_value : plan?.amount || null,
    payment_status : paymentStatus ? paymentStatus : null
  }
  if (section) {
    payload.section = section
  }
  if (subSection) {
    payload.sub_section = subSection
  }
  if (additionalLabel) {
    payload[`event_label3`] = additionalLabel
  }
  trackEvent(event_name,payload,null)
}
