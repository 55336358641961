import { generateHash, generateMinifiedQuery } from "../../client/Graphql/hash";

export const pageQuery = `query ($site: String!, $path: String, $pageId: String, $device: Device!, $includeContent: Boolean, $moduleLimit: Int, $moduleOffset: Int, $languageCode: String, $countryCode: String, $userState: String, $modules: [String!], $offset: Int, $platform:EntitlementDevice, $next: String) {
  page(
    site: $site
    path: $path
    pageId: $pageId
    device: $device
    includeContent: $includeContent
    moduleLimit: $moduleLimit
    moduleOffset: $moduleOffset
    languageCode: $languageCode
    countryCode: $countryCode
    userState: $userState
    modules: $modules
    offset: $offset
    platform :$platform
    next: $next
  ) {
    id
    moduleCount
    moduleIds
    title
    pageType
    metadataMap {
      title
      keywords
      description
      h1Title
      h2Title
      contentType
      contentUrl
      image
      isLiveStream
      duration
      uploadDate
      thumbnailUrl {
        r16x9
      }
    }
    modules {
      id
      moduleType
      ... on MastheadModule {
        setting {
          hideDate
        }
        layout {
          ...Layout
          ...PartialFonts
        }
      }
      ... on TextModule {
        rawText
        metadataMap
        layout {
          ...Layout
        }
      }
      ... on SearchModule {
        metadataMap
        layout {
          ...Layout
          ...PartialFonts
        }
      }
      ...on HistoryModule {
        id
        title
        metadataMap
         layout {
          ...Layout
          ...PartialFonts
        }
      }
      ...on CheckoutModule{
        id
        metadataMap
        layout {
          ...Layout
          ...PartialFonts
        }
      }
      ...on PlanBannerModule{
        id
        title
        moduleType
        metadataMap
        layout{
          ...Layout
          ...PartialFonts
        }
      }
      ...on QueueModule {
        id
        title
        metadataMap
         layout {
          ...Layout
          ...PartialFonts
        }
      }
      ...on ShowDetailModule {
        id
        metadataMap
        contentData {
          id
          gist {
            id
            title
            description
            contentType
            permalink
            imageGist {
              r32x9
              r16x9
              r4x3
              r3x4
              r1x1
              r9x16
            }
          }
          ... on Series {
            id
            title
            monetizationModels {
              id 
              type
            }
            monetizationBadgeIdentifier
            plans {
              planIds
              type
            }
            seasons {
              title
              episodes {
                id
                title
                gist {
                  id
                  title
                  description
                  contentType
                  permalink
                  imageGist {
                    r32x9
                    r16x9
                    r4x3
                    r3x4
                    r1x1
                    r9x16
                  }
                }
              }
            }
          trailers{
              id
            } 
          }
        }
        plans {
          ...VLMonetizationPlan
        }
         layout {
          ...Layout
          ...PartialFonts
        }
      }
      ...on CheckoutModule{
        id
        metadataMap
        layout {
          ...Layout
          ...PartialFonts
        }
      }
      ... on GameDetailModule {
        title
        metadataMap
        contentData {
          id
          gist {
            id
            title
            contentType
            permalink
            imageGist {
              r32x9
              r16x9
              r4x3
              r3x4
              r1x1
              r9x16
            }
          }
          ... on Game {
            id
            displayAttribute
            ticketUrl
            monetizationBadgeIdentifier
            monetizationModels{
              type
            }
            plans {
              type
              planIds
            }
            gist {
              id
              title
              contentType
              permalink
              imageGist {
                r32x9
                r16x9
                r4x3
                r3x4
                r1x1
                r9x16
              }
            }
            preview {
              id
              gist {
                id
                title
                  imageGist {
                    r32x9
                    r16x9
                    r4x3
                    r3x4
                    r1x1
                    r9x16
                  }
              }
              monetizationModels{
                id,
                type
              }
              plans{
                type,
                planIds,
                planGroupIds
              }
            }
            currentState
            states{
              live{
                startDateTime
              }
            }
            schedules {
              startDate
              endDate
              venue{
               id
               title
               gist {
                id
                site
                permalink
                title
                description
                imageGist {
                  r32x9
                  r16x9
                  r4x3
                  r3x4
                  r1x1
                  r9x16
                }
                primaryCategory {
                  id
                  title
                }
                contentType
                languageCode
                timezone
                scheduleStartDate
                scheduleEndDate
              }  
            }
          }
            league {
              id
              title
            }
            score{
              status
              type
              number
              homePoint
              awayPoint
            }
            homeTeam {
              owner
              id
              shortName
              currentSeason
              records {
                PST {
                  win
                  loss
                  points
                }
                REG{
                  win
                  loss
                  points
                }
                PRE{
                  win
                  loss
                  points
                }
                PIT{
                  win
                  loss
                  points
                }
              }
              gist {
                id
                title
                imageGist {
                  r32x9
                  r16x9
                  r4x3
                  r3x4
                  r1x1
                  r9x16
                }
              }
            }
            related {
              id
              title
              runtime
              gist {
                id
                title
                imageGist {
                  r32x9
                  r16x9
                  r4x3
                  r3x4
                  r1x1
                  r9x16
                }
                permalink
              }
            }
            awayTeam {
              owner
              id
              shortName
              currentSeason
              records {
                PST {
                  win
                  loss
                  points
                }
                REG{
                  win
                  loss
                  points
                }
                PRE{
                  win
                  loss
                  points
                }
                PIT{
                  win
                  loss
                  points
                }
              }
              gist {
                id
                title
                imageGist {
                  r32x9
                  r16x9
                  r4x3
                  r3x4
                  r1x1
                  r9x16
                }
              }
            }
            golfStats {
              currentRound
              currentStatusIdentifier
              eventStatus
              roundStates {
                round
                statusIdentifier
              }
            }
            persons{
              id
              title
              firstName
              lastName
              gist{
                id
                imageGist{
                  r1x1
                }
              }
              team{
                gist{
                  imageGist{
                    r1x1
                  }
                }
              }
            }
            championsData{
              id
            }
            livestreams {
              id
              title
              gist {
                imageGist {
                  r32x9
                  r16x9
                  r4x3
                  r3x4
                  r1x1
                  r9x16
                }
              }
              monetizationModels{
                id,
                type
              }
              plans{
                type,
                planIds,
                planGroupIds
              }
            }
            metadata {
              name
              value
            }
            sportsRadar{
              id
              seasonId
              widgetType
            }
            highlights {
              id
              runtime
              gist {
                id
                title
                contentType
                permalink
                imageGist {
                  r32x9
                  r16x9
                  r4x3
                  r3x4
                  r1x1
                  r9x16
                }
              }
            }
          }
        }
        plans {
          ...VLMonetizationPlan
        }
        layout {
          ...PartialFonts
          ...Layout
        }
      }

      ...on LinearchannelStandaloneModule{
        id
        metadataMap
          contentData{
          id
          ...PartialGist
       		...on Linearchannel{
            channels{
              id
              thumbnail
              title
              epgInfo{
                vlUrl
              } 
        
            }
          }
        }
        layout {
          ...PartialFonts
          ...Layout
        }
      }
      ...on LinearchannelDetailModule{
        id
        metadataMap
        contentData{
          id
          ...PartialGist
       		...on Linearchannel{
            channels{
              id
              title
              thumbnail
              epgInfo{
                vlUrl
              } 
            }
          }
        }
        layout {
          ...PartialFonts
          ...Layout
        }
      }
      ...on StandingsModule{
        metadataMap
        layout {
         ...Layout
         ...PartialFonts
       }
     }
     ...on LeaderboardModule{
       metadataMap
       layout {
        ...Layout
        ...PartialFonts
      }
    }
     ...on TeamChampionshipModule{
      metadataMap
      contentData {
        id
        title
        gist {
          id
          title
          description
          imageGist {
            r1x1
            r16x9
            r16x9
          }
        }
        ...on Game{
          championsData{
            id
          }
          golfStats {
            currentRound
            currentStatusIdentifier
            eventStatus
            roundStates {
              round
              statusIdentifier
            }
          }
        }
      }
      setting {
        hideDate
        hideTitle
        lazyLoad
        seeAll
        seeAllCard
        showMore
        seeAllPermalink
        showMoreCta
      }
      layout {
       ...Layout
       ...PartialFonts
       }
     }
     ...on LeaderboardModule{
     metadataMap
     contentData{
     ... on Game {
      ...PartialGameContent_1
       }
     } 
     layout {
      ...Layout
      ...PartialFonts
     }
    }
      ... on ArticleDetailModule {
        id
        title
        contentData {
          ... on Article {
            metadata {
              name
              value
            }
            publishDate
            updateDate
            articleAssets {
              url
            }
            readTime
            summaryText
            gist {
              id
              description
              title
              contentType
              permalink
              imageGist {
                r32x9
                r16x9
                r4x3
                r3x4
                r1x1
                r9x16
              }
            }
            imageList{
              id
              name
              url
            }
            author {
              name
            }
          }
        }
        layout {
          ...PartialFonts
          ...Layout
        }
        metadataMap
      }

      ... on VideoPlaylistDetailModule {
        id
        contentData {
          id
          title
          gist {
            id
            contentType
            title
            permalink
            description
          }
          ... on VideoPlaylist {
            metadata{
              name
              value
            }
            videoList {
              gist {
                id
                title
                description
                permalink
                imageGist {
                  r32x9
                  r16x9
                  r4x3
                  r3x4
                  r1x1
                  r9x16
                }
              }
            }
          }
        }
        layout {
          ...PartialFonts
          ...Layout
        }
        metadataMap
      }
      ... on StandaloneVideoPlayerModule {
        title
        subtitle
        contentData {
          id
          ...PartialGist
          ... on Video {
            ...PartialVideo_1
          }
        }
        metadataMap
        layout {
          ...PartialFonts
          ...Layout
        }
      }
      ... on CuratedTrayModule {
        title
        subtitle
        contentType
        contentData {
          id
          ...PartialGist
          ... on Video {
            ...PartialVideo_1
            
          }
          ... on Series {
            monetizationBadgeIdentifier
            plans {
              type
              planIds
            }
          }
          ... on Game {
            ...PartialGameContent_1
          }
          ... on Article {
            updateDate
            readTime
            gist {
              id
              description
            }
            author {
              name
            }
          }
        ... on Series {
          trailers{
            id
          }
        }
          ... on VideoPlaylist {
            videoList {
              id
            }
          }
        }
        metadataMap
        layout {
          ...PartialFonts
          ...Layout
        }
      }
      ... on TveActivateModule {
        id
        title
        metadataMap
        layout {
          ...Layout
        }
      }

      ... on RedeemModule {
        id
        title
        metadataMap
        layout {
          ...Layout
        }
      }
      ... on TveModule {
        id
        title
        metadataMap
        layout {
          ...Layout
        }
      }
      ... on GeneratedTrayModule {
        title
        subtitle
        next
        verticalVideosOnly
        contentData {
          id
          ...PartialGist
          ... on Video {
            ...PartialVideo_1
          }
          ... on Game {
            ...PartialGameContent_1
          }
          ... on Article {
            updateDate
            readTime
            author {
              name
            }
          }
          ... on Series {
            trailers{
              id
            }
          }
        }
        metadataMap
        layout {
          ...PartialFonts
          ...Layout
        }
      }
      ... on SubscriptionModule {
        metadataMap
        layout {
          ...Layout
        }
      }
      ... on AuthenticationModule {
        metadataMap
        layout {
          ...Layout
        }
      }
      ...on LibraryModule {
        id
        metadataMap
        moduleType
        layout {
          ...PartialFonts
          ...Layout
        }
      }
      ... on UserManagementModule {
        metadataMap
        rawText
        layout {
          ...Layout
          fontSetting {
            tab {
              fontFamilyName
              fontSize
              fontWeight
            }
            title {
              fontFamilyName
              fontSize
              fontWeight
            }
            subtitle {
              fontFamilyName
              fontSize
              fontWeight
            }
            button {
              fontFamilyName
              fontSize
              fontWeight
            }
            teamName {
              fontFamilyName
              fontSize
              fontWeight
            }
            playerName {
              fontFamilyName
              fontSize
              fontWeight
            }
          }
        }
      }
      ... on VideoDetailModule {
        id
        moduleType
        metadataMap
        title
        contentData {
          id
          title
          gist {
            id
            contentType
            permalink
            title
            description
            imageGist {
              r16x9
            }
          }

          ...PartialVideo_1
          ...on Video {
            plans {
              type
              planIds
            }
            relatedVideos {
              id
              title
              gist {
                id
                contentType
                permalink
                title
                description
                imageGist {
                  r16x9
                }
              }
              ...PartialVideo_1
            }
          }
        
        }
        metadataMap
        plans {
          ...VLMonetizationPlan
        }
        layout {
          ...PartialFonts
          ...Layout
        }
      }
      ... on CollectionDetailModule {
        	id
        	title
        	subtitle
        	moduleType
        	metadataMap
        	layout{
            id
            settings
            styles
            view
            blockName
            ...PartialFonts_1
          }
        	contentData{
           ...on Collection{
  					...PartialCollectionContent_1
          }
          id
          title
          gist {
              id
              title
              description
            	permalink
          }
        }
      }
      ... on CategoryDetailModule {
        id
        title
        next
        contentData {
          id
          ...PartialGist
          ... on Video {
            ...PartialVideo_1
          }
        }
        layout {
          ...Layout
        }
      }
      ... on TagDetailModule {
        title
        contentData {
          id
          ...PartialGist
          ... on Video {
            ...PartialVideo_1
          }
        }
        layout {
          ...Layout
        }
      }
      
      ... on ViewPlanModule {
        description
        metadataMap
        layout {
          ...PartialFonts
          ...Layout
        }
      }
      
      ... on ScheduleModule {
        id
        title
        subtitle
        metadataMap
        contentData {
          id
          ...PartialGist
          ... on Game {
            ...PartialGameContent_1
          }
        }
        layout {
          ...Layout
          ...PartialFonts
        }
      }
      ... on LocateModule {
        id
        title
        metadataMap
        layout {
          ...Layout
          ...PartialFonts
        }
      }
      ... on FooterModule {
        metadataMap
        layout {
          ...Layout
        }
      }
    }
  }
}
fragment PartialGist on Content {
  id
  gist {
    id
    title
    contentType
    permalink
    description
    description
    imageGist {
      r32x9
      r16x9
      r4x3
      r3x4
      r1x1
      r9x16
    }
  }
}
fragment PartialFonts on ModuleLayout {
  fontSetting {
    monetizationCtaHeading{
      fontFamilyName
      fontSize
      fontWeight
    }
    monetizationCtaSubTitle{
      fontFamilyName
      fontSize
      fontWeight
    }
    monetizationCtaPrice{
      fontFamilyName
      fontSize
      fontWeight
    }
    featureItem{
      fontFamilyName
      fontSize
      fontWeight
    }
    ctaHeading{
      fontFamilyName
      fontSize
      fontWeight
    }
    sectionHeading{
      fontFamilyName
      fontSize
      fontWeight
    }
    offerCodeBannerTitle{
      fontFamilyName
      fontSize
      fontWeight
    }
    offerCodeBannerCta{
      fontFamilyName
      fontSize
      fontWeight
    }
    offerCodeBannerAmount{
      fontFamilyName
      fontSize
      fontWeight
    }
    offerCodeBannerPlanOff{
      fontFamilyName
      fontSize
      fontWeight
    }
    offerCodeBannerPlanTitle{
      fontFamilyName
      fontSize
      fontWeight
    }
    offerCodeBannerDescription{
      fontFamilyName
      fontSize
      fontWeight
    }
    regularSeasonTitle{
      fontFamilyName
      fontSize
      fontWeight
    }
    previewTitle {
      fontFamilyName
      fontSize
      fontWeight
    }
     previewDescription {
      fontFamilyName
      fontSize
      fontWeight
    }
    position{
      fontFamilyName
      fontSize
      fontWeight
    }
    pointsTitle{
      fontFamilyName
      fontSize
      fontWeight
    }
    plan{
      fontFamilyName
     fontSize
     fontWeight 
   }
   loginWallTitle{
     fontFamilyName
     fontSize
     fontWeight
   }
   loginButton{
     fontFamilyName
     fontSize
     fontWeight
   }
   continueButton{
     fontFamilyName
     fontSize
     fontWeight
   }
   emailWallTitle{
     fontFamilyName
     fontSize
     fontWeight
   }
   subscriptionWallTitle{
     fontFamilyName
     fontSize
     fontWeight
   }
    title {
      fontFamilyName
      fontSize
      fontWeight
    }
    trayItemTitle {
      fontFamilyName
      fontSize
      fontWeight
    }
    subtitle {
      fontFamilyName
      fontSize
      fontWeight
    }
    team {
      fontFamilyName
      fontSize
      fontWeight
    }
    button {
      fontFamilyName
      fontSize
      fontWeight
    }
    articleTitle {
      fontFamilyName
      fontSize
      fontWeight
    }
    articleDescription {
      fontFamilyName
      fontSize
      fontWeight
    }
    tab {
      fontFamilyName
      fontSize
      fontWeight
    }
    widget {
      fontFamilyName
      fontSize
      fontWeight
    }
    videoTitle {
      fontFamilyName
      fontSize
      fontWeight
    }
    videoDescription {
      fontFamilyName
      fontSize
      fontWeight
    }
    searchSuggestionTitle {
      fontFamilyName
      fontSize
      fontWeight
    }
    searchSuggestionDate {
      fontFamilyName
      fontSize
      fontWeight
    }
    navigation {
      fontFamilyName
      fontSize
      fontWeight
    }
    scoreSummary {
      fontFamilyName
      fontSize
      fontWeight
    }
    individualPlayerScore {
      fontFamilyName
      fontSize
      fontWeight
    }
    position {
      fontFamilyName
      fontSize
      fontWeight
    }
    infoOverlayTitle {
      fontFamilyName
      fontSize
      fontWeight
    }

    popupHeading {
      fontFamilyName
      fontSize
      fontWeight
    }
    popupCtaTitle {
      fontFamilyName
      fontSize
      fontWeight
    }
    popupCtaSubtitle {
      fontFamilyName
      fontSize
      fontWeight
    }
    popupPrice {
      fontFamilyName
      fontSize
      fontWeight
    }
    popupDetail {
      fontFamilyName
      fontSize
      fontWeight
  }
  timerTitle {
    fontFamilyName
    fontSize
    fontWeight
  }
  timerValue{
    fontFamilyName
    fontSize
    fontWeight
  }
}
}
fragment PartialFonts_1 on ModuleLayout {
  fontSetting {
    title {
      fontFamilyName
      fontSize
      fontWeight
    }
    popupHeading {
      fontFamilyName
      fontSize
      fontWeight
    }
    popupCtaTitle {
      fontFamilyName
      fontSize
      fontWeight
    }
    popupCtaSubtitle {
      fontFamilyName
      fontSize
      fontWeight
    }
    popupPrice {
      fontFamilyName
      fontSize
      fontWeight
    }
    popupDetail {
      fontFamilyName
      fontSize
      fontWeight
    }
  }
}
fragment PartialVideo_1 on Video {
  parentalRating
  runtime
  plans {
    planIds
    type
  }
  monetizationBadgeIdentifier
  isVerticalVideo
  metadata {
    name
    value
  }
  trailers{
    id
  }
  streamingInfo{
    isLiveStream
  }
}
fragment PartialVideo_2 on Video {
  id
  title
  plans {
    planIds
    type
  }
  gist{
    imageGist{
      r16x9
    }
  }
  parentalRating
  runtime
  monetizationBadgeIdentifier
  trailers{
    id
  }
}
fragment Layout on ModuleLayout {
  id
  settings
  styles
  view
  type
  blockName
  isMasthead
}
fragment VLMonetizationPlan on MonetizationPlan {
  id
  name
  description
  identifier
  monetizationModel
  planDetails {
    ...VLPlanDetails
  }
  planDisplayOrder
  renewable
  renewalCyclePeriodMultiplier
  renewalCycleType
  featureSetting {
    ...VLPlanFeatureSettings
  }
  metadata {
    name
    value
  }
}

fragment VLPlanDetails on PlansDetails {
  title
    description
    countryCode
    visible
    displayStrikeThroughPrice
    hidePlanPrice
    isDefault
    isPurchaseEnabled
    purchaseAmount
    isRentEnabled
    numberOfAllowedStreams
    numberOfAllowedDevices
    recurringPaymentAmount
    recurringPaymentCurrencyCode
    redirectUrl
  	rentAmount
  	rentalPeriodUnit
    rentalPeriodValue
  featureDetails {
    ...VLPlanDetailFeature
  }
  supportedDevices
  channel_ids
  taxIncluded
}


fragment VLPlanDetailFeature on PlanDetailFeature {
  textToDisplay
  value
  valueType
}

fragment VLPlanFeatureSettings on PlanFeatureSettings {
  includingAds
  isBeamingAllowed
  isHdStreaming
  isDownloadAllowed
  numberOfAllowedDevices
  numberOfAllowedStreams
  contentConsumption
  isLoginRequired
}
fragment PartialGameContent_1 on Game {
  persons{
    id
    title
    firstName
    lastName
    gist{
      id
      imageGist{
        r1x1
      }
    }
    team{
      gist{
        imageGist{
          r1x1
        }
      }
    }
  }
  championsData{
    id
  }
  id
  monetizationBadgeIdentifier
  plans {
    planIds
    type
  }
  author {
    name
  }
  gist {
    id
    title
  }
  livestreams {
    id
    title
  }
  displayAttribute
  metadata {
    name
    value
  }
  ticketUrl
  league{
    id
    title
  }
  homeTeam {
    id
    title
    shortName
    currentSeason
    records {
      PST {
        win
        loss
        points
      }
      REG{
        win
        loss
        points
      }
      PRE{
        win
        loss
        points
      }
      PIT{
        win
        loss
        points
      }
    }
    gist {
      title
      description
      imageGist {
        r32x9
        r16x9
        r4x3
        r3x4
        r1x1
        r9x16
      }
    }
  }
  awayTeam {
    id
    title
    shortName
    currentSeason
    records {
      PST {
        win
        loss
        points
      }
      REG{
        win
        loss
        points
      }
      PRE{
        win
        loss
        points
      }
      PIT{
        win
        loss
        points
      }
    }
    gist {
      title
      description
      imageGist {
        r32x9
        r16x9
        r4x3
        r3x4
        r1x1
        r9x16
      }
    }
  }
  highlights {
    id
    title
  }
  webliveUrl
  currentState
  preview {
    id
  }
  states {
    live {
      startDateTime
    }
  }
  schedules {
    startDate
    endDate
    venue {
       id
      title
      gist {
        id
        site
        permalink
        title
        description
        imageGist {
          r32x9
          r16x9
          r4x3
          r3x4
          r1x1
          r9x16
        }
        primaryCategory {
          id
          title
        }
        contentType
        languageCode
        timezone
        scheduleStartDate
        scheduleEndDate
      }
    }
  }
  score {
    status
    type
    number
    homePoint
    awayPoint
  }
  golfStats {
    currentRound
    currentStatusIdentifier
    eventStatus
    roundStates {
      round
      statusIdentifier
    }
    holesMappingData{
      holeNumber,
      position
    }
  }
  broadcaster
}
fragment PartialGameContent_2 on Game {
  id
  livestreams {
    id
    title
  }
  plans {
    planIds
    type
  }
  monetizationBadgeIdentifier
  author {
    name
  }
  gist {
    id
    title
  }
  displayAttribute
  webliveUrl
  currentState
  preview {
    id
  }
  broadcaster
}
fragment PartialCollectionContent_1 on Collection{
	gist{
    id
    title
    permalink
    contentType
    imageGist{
      r16x9
    }
    description
  }
  multiviews {
    id
    title
    gist {
      id
      title
      permalink
      contentType
      scheduleStartDate
      scheduleEndDate
    }
     ... on Game{
      id
    	title
    	livestreams{
      	id
        title
    	}
      persons {
        id
      }
      currentState
    }
    ...PartialGist
    ...PartialVideo_2
    ...PartialGameContent_2
  }
  golfStats{
    ...GolfEventStats
  }
}
fragment GolfEventStats on GolfStats {
  currentRound
  currentStatusIdentifier
  eventStatus
  roundStates {
    round
    statusIdentifier
  }
  holesMappingData {
    holeNumber
    position
    holePar
    holeYard
  }
  singularPayload {
    p1score
    p2score
    p3score
    p4score
    hole
    toPlay
    par
    p1fn
    p1sn
    p2fn
    p2sn
    p3fn
    p3sn
    p4fn
    p4sn
    p1shot
    p2shot
    p3shot
    p4shot
    t1logo
    t2logo
    t3logo
    t4logo
    t1divider
    t2divider
    t3divider
    t4divider
    lscore
    ltext
    t1code
    t2code
    t3code
    t4code
    p1id
    p2id
    p3id
    p4id
    p1photo
    p2photo
    p3photo
    p4photo
    iSlate
    title
    leaderVis
    toPlayVis
    t1Wordmark
    t1color {
      primaryColor
      secondaryColor
    }
    t2color {
       primaryColor
      secondaryColor
    }
    t3color {
       primaryColor
      secondaryColor
    }
    t4color {
       primaryColor
      secondaryColor
    }
    }
}  
`
export const minifiedPageQuery=generateMinifiedQuery(pageQuery);
export const pageQuerySHA = generateHash(minifiedPageQuery);
